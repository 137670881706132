(function() {
  const contactForm = document.getElementById('kontakt');
  if (contactForm === null || contactForm === 'undefined') {
    console.warn("No contact form found.");
    return;
  }

  const contactFormInput = contactForm.getElementsByClassName('form-control');
  let waiting = true;

  for (let i = 0; i < contactFormInput.length; i++) {
    contactFormInput[i].addEventListener('focus', function() {
      if (waiting) {
        waiting = false;
        addTimer();
      }
    });
  }  
})();

function addTimer() {
  var timer = document.getElementById('button-timer').getElementsByClassName('circle')[0];
  timer.classList.remove('hide-visually');

  var timerInfo = document.getElementById('timer-info');
  timerInfo.classList.remove('hide-visually');

  var progressBar = document.getElementsByClassName('e-c-progress')[0];
  var pointer = document.getElementById('e-pointer');
  var length = Math.PI * 2 * 100;
  var displayOutput = document.getElementsByClassName('display-remaining-time')[0];
  var timerDuration = 20;   // Timer duration in seconds

  function update(value, timePercent) {
    var offset = - length - length * value / (timePercent);
    progressBar.style.strokeDashoffset = offset; 
    pointer.style.transform = 'rotate(' + 360 * (value / timePercent) + 'deg)';
  }

  function startTimer (seconds){ //counts time, takes seconds
    var remainTime = Date.now() + (seconds * 1000);
    displayTimeLeft(seconds);
    
    intervalTimer = setInterval(function(){
      timeLeft = Math.round((remainTime - Date.now()) / 1000);
      displayTimeLeft(timeLeft);
      update(timeLeft, timerDuration);
      if(timeLeft <= 0){
        clearInterval(intervalTimer);
        var contactForm = document.getElementsByClassName('contact-form')[0];
        contactForm.setAttribute('action', 'kontakt/contactform.php');
        var contactSubmitButton = document.getElementById('contact-form-submit');
        contactSubmitButton.removeAttribute('disabled');
        return ;
      }
    }, 1000);
  }

  function displayTimeLeft (timeLeft){ //displays time on the input
    var minutes = Math.floor(timeLeft / 60);
    var seconds = timeLeft % 60;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    displayOutput.textContent = minutes + ':' + seconds;
  }
  
  progressBar.style.strokeDasharray = length;
  startTimer(timerDuration);
}
