var GDPRInfo = (function() {
  const cookieDuration = 365; // in days
  const cookieName = 'sawGDPRNotice';
  const cookieValue = 'true';

  function setCookie(name, value, daysValid) {

    let d = new Date();
    d.setTime(d.getTime() + (daysValid * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/;samesite=lax';
    
  }

  function getCookie(name) {

    name += '=';
    let ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';

  }

  document.addEventListener('DOMContentLoaded', function(event) {

    const gdprNotice = document.querySelector('#gdpr-notice');

    if (!gdprNotice) {
      return;
    }

    const gdprButton = gdprNotice.querySelector('#gdpr-button-ack');

    if (getCookie(cookieName).length == 0) {
      gdprNotice.classList.remove('hide-notice');
    }

    gdprButton.addEventListener('click', function() {
      gdprNotice.classList.add('hide-notice');
      setCookie(cookieName, cookieValue, cookieDuration);
    });

  });

})();
